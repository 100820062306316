<template>
  <v-container fluid>
    <PageNavigation :items="items" />

    ​<v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.purchaseorder')"
          icon="mdi-apple-finder"
          class="pt-4 mt-3"
        >
          <v-row class="my-0">
            <v-col cols="12" lg="2" md="3" sm="12" xs="12">
              <v-text-field
                :label="$vuetify.lang.t('$vuetify.search')"
                v-model="search"
                prepend-inner-icon="mdi-magnify"
                clearable
              />
            </v-col>

            <v-col cols="12" lg="2" md="3" sm="12" xs="12">
              <v-select
                :items="statuses"
                :label="$vuetify.lang.t('$vuetify.status')"
                v-model="searchForm.status"
                clearable
                @change="handleFilters"
              />
            </v-col>

            <v-col cols="12" lg="2" md="3" sm="12" xs="12">
              <v-select
                :items="supplierData"
                :label="$vuetify.lang.t('$vuetify.warehouseName')"
                v-model="searchForm.supplier_id"
                clearable
                @change="handleFilters"
              />
            </v-col>

            <v-spacer />

            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="12"
              class="d-flex align-center justify-end"
            >
              <v-btn
                class="ma-0"
                color="green"
                elevation="0"
                @click="handleDialog"
                :width="$vuetify.breakpoint.mdAndUp ? '' : '100%'"
                v-if="permissionCheck('add')"
              >
                <v-icon left>mdi-account-plus</v-icon>
                {{ $vuetify.lang.t("$vuetify.add") }}
              </v-btn>
            </v-col>
          </v-row>

          <v-row class="mt-0 mb-8">
            <v-col cols="12">
              <TableImg
                :head="headers"
                :data="datas"
                :search="search"
                :tableLoading="isLoading"
                v-on:edititem="handleEditDialog"
                v-on:audit="handleAuditDialog"
                v-on:abolish="handleAbolishDialog"
                v-on:toBeReceived="handleToBeReceiveDialog"
                v-on:haveArrived="handleHaveArriveDialog"
                v-on:deleteitem="handleDeleteDialog"
              />
            </v-col>
          </v-row>
        </base-material-card>

        <PurchaseOrderCRUD
          :dialog.sync="dialog"
          :addAction.sync="addAction"
          :editAction.sync="editAction"
          :auditAction.sync="auditAction"
          :abolishAction.sync="abolishAction"
          :obj.sync="obj"
          :real_price.sync="real_price"
          :supplierName.sync="supplierName"
          :selectedProduct.sync="selectedProduct"
          @handleData="handlePurchaseOrder"
          @handlePriceDetailData="handlePriceDetailData"
          v-on:selectProduct="handleSelect"
          v-on:paymentDetail="handleDetail"
          v-on:selectSupplier="handleSupplier"
          v-on:ReceiptCRUD="handleReceipt"
          v-on:getData="getData"
        />

        <SelectProduct
          :dialogProduct.sync="dialogProduct"
          :selected.sync="selectedProduct"
        />

        <SelectSupplier
          :dialogSupplier.sync="dialogSupplier"
          :supplierObj.sync="obj"
        />

        <PaymentDetail
          :dialogDetail.sync="dialogDetail"
          :priceDetail.sync="priceDetail"
        />

        <ReceiptCRUD
          :dialogReceipt.sync="dialogReceipt"
          @getreceipt="handleUpdateReceipt"
          :myObj="obj"
          :complete.sync="complete"
        />

        <DialogDelete
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  purchaseList,
  deletePurchase,
  purchaseUpser,
  purchaseInfo,
  checkPurchase,
  receivePurchase,
  cancelPurchase,
  postInfoPurchase,
  getTemp,
  pucharPdf,
} from "@/api/purchase";

import { orderList } from "@/api/order";
import { supplierList } from "@/api/basicdata";

const clone = (obj) => Object.assign({}, obj);
const renameKey = (object, key, newKey) => {
  const clonedObj = clone(object);
  const targetKey = clonedObj[key];

  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

const newObj = () => {
  return {
    id: "",
    po: "",
    sale_po: "",
    sale_order_id: "",
    supplier_id: "",
    products: [
      {
        id: "",
        nick_name: "",
        invoice_name: "",
        type: 1,
        number: 0,
        price: 0,
        name: "",
      },
    ],
    number: 0,
    price: 0,
    tax: "",
    contain_tax: true,
    currency: "USD",
    rate: "",
    warehouse_id: "",
    status: 0,
    attach: "",
    rate: "",
    price_detail: "", // {cash:online:other:} 总价明细
    purchase_type: "",
    account_period: "",
  };
};

const newDelete = () => {
  return {
    id: "",
    po: "",
    sale_po: "",
    sale_order_id: "",
    supplier_id: "",
    products: [
      {
        id: "",
        nick_name: "",
        invoice_name: "",
        type: 1,
        number: 0,
        price: 0,
        name: "",
      },
    ],
    number: 0,
    price: 0,
    tax: 0,
    currency: "",
    rate: 0,
    warehouse_id: "",
    status: 0,
    attach: "",
    rate: "",
    price_detail: "", // {cash:online:other:} 总价明细
    purchase_type: "",
    account_period: "",
  };
};

const newSupplierSearch = () => {
  return {
    key: "",
    skip: 0,
    limit: 100,
  };
};

const newSearchForm = () => {
  return {
    supplier_id: "",
    status: "",
    sale_po: "",
    po: "",
    skip: 0,
    limit: 5000000,
  };
};

const newPriceDetail = () => {
  return {
    cash: 0,
    online: 0,
    other: 0,
    remarks: "",
  };
};

export default {
  name: "PurchaseOrder",
  components: {
    PageNavigation: () => import("@/components/pageNavigation"),
    TableImg: () => import("@/components/tableImg"),
    PurchaseOrderCRUD: () => import("@/components/purchase/purchaseOrderCRUD"),
    SelectProduct: () => import("@/components/orders/selectProduct"),
    PaymentDetail: () => import("@/components/orders/paymentDetail"),
    SelectSupplier: () => import("@/components/purchase/selectSupplier"),
    ReceiptCRUD: () => import("@/components/purchase/receiptCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
  },
  data() {
    return {
      isLoading: true,
      dialog: false,
      dialogProduct: false,
      dialogDetail: false,
      dialogSupplier: false,
      dialogReceipt: false,
      dialogDelete: false,
      addAction: false,
      editAction: false,
      auditAction: false,
      abolishAction: false,
      real_price: 0,
      complete: false,
      search: "",
      supplierName: "",
      orderInfo: {},
      orderInfo: {},
      priceDetail: newPriceDetail(),
      items: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.purchase") },
        { text: this.$vuetify.lang.t("$vuetify.purchaseorder") },
      ],
      statuses: [
        {
          text: this.$vuetify.lang.t("$vuetify.orderCheck"),
          value: "1",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tobeReceived"),
          value: "2",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.offStocks"),
          value: "3",
        },
        // {
        //   text: this.$vuetify.lang.t("$vuetify.all"),
        //   value: "",
        // },
      ],
      headers: [
        {
          text: this.$vuetify.lang.t("$vuetify.itemNo"),
          value: "itemNo",
          width: "100",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.poNo"),
          value: "po",
        },
        {
          text: "Total Product Type",
          value: "goods_num",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.goodsTotal"),
          value: "number",
          sortable: false,
          align: "center",
        },
        {
          text: "Total Received",
          value: "totalReceive",
          sortable: false,
          align: "center",
        },
        {
          text: "Total Return",
          value: "totalReturn",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.ttlPrice"),
          value: "price",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.tax"),
          value: "tax",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.currency"),
          value: "currency",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.warehouseInfo"),
          value: "warehouse_info.name",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.orderDate"),
          value: "gen_time",
          sortable: false,
          align: "center",
        },
        {
          text: this.$vuetify.lang.t("$vuetify.opt"),
          value: "purchaseActions",
          width: "180",
          sortable: false,
          align: "center",
        },
      ],
      supplierData: [],
      warehouses: [],
      datas: [],
      selectedProduct: [],
      supplierSearch: newSupplierSearch(),
      obj: newObj(),
      deleteObj: newDelete(),
      searchForm: newSearchForm(),
    };
  },
  methods: {
    getData() {
      this.datas = [];
      this.warehouses = [];
      this.supplierData = [];

      purchaseList(this.searchForm)
        .then((data) => {
          if (data.items != null) {
            this.datas = [...data.items];

            for (var i = 0; i < this.datas.length; i++) {
              this.datas[i].itemNo = i + 1;
              this.datas[i].warehouse = this.datas[i].warehouse_info.name;
              this.datas[i].supplier = this.datas[i].supplier_info.company;
              this.datas[i].totalReceive = 0;
              this.datas[i].totalReturn = 0;

              for (var j = 0; j < this.datas[i].products.length; j++) {
                this.datas[i].totalReceive += this.datas[i].products[
                  j
                ].real_number;
                this.datas[i].totalReturn += this.datas[i].products[
                  j
                ].return_number;
              }
            }
          } else {
            this.datas = [];
            this.$toast.info("No Data Found");
          }
        })
        .catch((err) => {
          console.log(err);
        });

      supplierList(this.supplierSearch)
        .then((data) => {
          this.warehouses = [...data.items];

          this.warehouses.forEach((p) => {
            const supplierObj = { text: "", value: "" };

            supplierObj.text = p.company;
            supplierObj.value = p.id;

            this.supplierData.push(supplierObj);
          });

          // this.supplierData.push({
          //   text: this.$vuetify.lang.t("$vuetify.all"),
          //   value: "",
          // });
        })
        .catch((err) => {
          console.log("Supplier List Error", err);
        });

      this.isLoading = false;
    },
    delorder(id) {
      deletePurchase(id).then((res) => {
        this.getData();
      });
    },
    abolish(obj) {
      cancelPurchase({ id: obj.id, cancel_reason: obj.cancel_reason }).then(
        (res) => {
          this.getData();
        }
      );
    },
    add(data) {
      const arr = JSON.parse(JSON.stringify(data.products));
      const obj = {};

      arr.forEach((item) => {
        if (obj[item.id] === undefined) {
          obj[item.id] = 0;
        }

        delete item.info;
        item.number = parseFloat(item.number);
        obj[item.id] += item.number;
        item.price = parseFloat(item.price);
        item.give_number = parseFloat(item.give_number);
      });

      let some = null;

      if (this.orderInfo && this.orderInfo.products) {
        some = this.orderInfo.products.every((res) => {
          return res.number - res.purchase_number >= obj[res.id];
        });
      }
      // this.upsertData.price = Number(this.saleAllNumber)
      // this.upsertData.number = Number(this.goodPrice)
      // arr.forEach(vItem => {
      //   delete vItem.image
      //   delete vItem.totalPrice
      //   delete vItem.code
      // })

      data.products = JSON.stringify(arr);

      // this.upsertData.attach = JSON.stringify(this.des_files)
      // if (!some && this.orderInfo.products) {
      //   this.$confirm(this.$t('all.quantity2') + '?', this.$t('tools.prompt'), {
      //     confirmButtonText: this.$t('tools.confirm'),
      //     cancelButtonText: this.$t('tools.cancel')
      //   }).then(res => {
      //     purchaseUpser(this.data).then(res => {
      //       this.reverseBack(this.$t('all.SuccessfulOperation'))
      //     })
      //   })
      // } else {

      purchaseUpser(data)
        .then((res) => {
          this.getData();
        })
        .catch((err) => {
          console.log("Purchase Upsert Error", err);
          this.$toast.error("Purhcase Upsert Error");
        });
      // }
    },
    approval(obj) {
      checkPurchase({ id: this.obj.id }).then((res) => {
        this.getData();
      });
    },
    receive(data) {
      const arr = {};
      data.products.forEach((item) => {
        const obj = {};
        obj.real_number = parseFloat(item.new_real_number);
        obj.real_give_number = parseFloat(item.give_number);
        arr[item.id] = obj;
      });
      receivePurchase({
        id: data.id,
        real_receive: JSON.stringify(arr),
        real_price: this.real_price,
        complete: this.complete,
      }).then((res) => {
        // this.showDelivery = false
        // this.reverseBack(res.error)
        this.getData();
      });
    },
    addPriceDetail() {
      const obj = {
        cash: Number(this.priceDetail.cash),
        online: Number(this.priceDetail.online),
        other: Number(this.priceDetail.other),
        remarks: this.priceDetail.remarks,
      };
      return JSON.stringify(obj);
    },
    addToSelectedProduct(obj) {
      this.obj = obj;
      this.selectedProduct = this.obj.products;
      // for (var i = 0; i < this.selectedProduct.length; i++) {
      //     this.selectedProduct[i].qty = this.selectedProduct[i].number;
      // }
    },
    resetObj() {
      this.obj.id = "";
      this.obj.po = "";
      this.obj.sale_po = "";
      this.obj.sale_order_id = "";
      this.obj.supplier_id = "";
      this.obj.number = 0;
      this.obj.price = 0;
      this.obj.tax = 0;
      this.obj.currency = "";
      this.obj.rate = 0;
      this.obj.warehouse_id = "";
      this.warehouse = "";
      this.obj.status = 0;
      this.obj.products = [];
      this.supplier = "";
    },
    handleDialog() {
      this.dialog = true;
      this.addAction = true;
      this.resetObj();
      this.selectedProduct = [];
    },
    handleEditDialog(obj) {
      if (this.permissionCheck("modify")) {
        this.dialog = true;
        this.editAction = true;
        this.addToSelectedProduct(obj);
      }
    },
    handleAuditDialog(obj) {
      this.dialog = true;
      this.auditAction = true;
      this.addToSelectedProduct(obj);
    },
    handleAbolishDialog(obj) {
      this.dialog = true;
      this.abolishAction = true;
      this.addToSelectedProduct(obj);
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm() {
      this.dialogDelete = false;
      this.delorder(this.deleteObj.id);
    },
    handlePurchaseOrder(obj) {
      this.dialog = false;

      obj.price_detail = this.addPriceDetail();

      if (this.abolishAction == true) {
        this.abolish(obj);
      } else if (this.addAction == true || this.editAction == true) {
        this.add(obj);
      } else if (this.auditAction == true) {
        this.approval(obj);
      }

      this.addAction = false;
      this.editAction = false;
      this.auditAction = false;
      this.abolishAction = false;
      this.selectedProduct = [];
    },
    handleToBeReceiveDialog(obj) {
      this.dialog = true;
      this.addToSelectedProduct(obj);
    },
    handleHaveArriveDialog(obj) {
      this.dialog = true;
      this.addToSelectedProduct(obj);
    },
    handleReceipt() {
      this.dialogReceipt = true;
    },
    handleUpdateReceipt(obj) {
      this.dialogReceipt = false;
      this.dialog = false;
      this.receive(obj);
    },
    handlePriceDetailData(obj) {
      this.priceDetail = obj;
    },
    handleSelect() {
      this.dialogProduct = true;
    },
    handleDetail() {
      this.dialogDetail = true;
    },
    handleSupplier() {
      this.dialogSupplier = true;
    },
    handleSelectProduct(obj) {
      this.dialogProduct = false;

      for (var i = 0; i < obj.length; i++) {
        // obj[i].qty = 0;
        // obj[i].price = 0;
        obj[i].nick_name = obj[i].name;
        obj[i].type = 1;

        this.selectedProduct.push(obj[i]);
        this.selectedProduct = [...new Set(this.selectedProduct)];
      }
    },
    handleFilters() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>